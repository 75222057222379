import React, { useEffect, useState, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./Slider.module.css";
import leftArrow from "../../Icons/left.svg";
import rightArrow from "../../Icons/right.svg";

export default function SimpleSlider({ images }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [carouselWidth, setCarouselWidth] = useState(0);
  const imageRef = useRef(null);

  const settings = {
    dots: false,
    fade: true,
    lazyLoad: true,
    infinite: images.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    afterChange: (current) => setCurrentIndex(current),
    prevArrow: images.length > 1 ? <CustomArrow direction="left" /> : null,
    nextArrow: images.length > 1 ? <CustomArrow direction="right" /> : null,
  };

  const updateWidth = () => {
    if (imageRef.current) {
      setCarouselWidth(imageRef.current.clientWidth);
    }
  };

  useEffect(() => {
    updateWidth();
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, [currentIndex]);

  return (
    <div
      className={styles.slider}
      style={{
        width: carouselWidth || "1000px",
        margin: "0 auto",
        transition: "width 0.3s ease",
      }}
    >
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index}>
            <img
              ref={index === currentIndex ? imageRef : null} // Attach ref only to the active slide
              className={styles.image}
              src={image}
              alt={`Slide ${index + 1}`}
              onLoad={updateWidth} // Ensure width updates on image load
            />
            <div className={styles.photoCarousel}>
              {index + 1} of {images.length}
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

// Custom Arrow Component
const CustomArrow = ({ direction, onClick }) => {
  return (
    <div
      onClick={onClick}
      style={{
        position: "absolute",
        top: "calc(100% - 38px)",
        left: direction === "left" ? "0%" : "60px",
        transform: "translateY(10%)",
        marginTop: "8px",
        cursor: "pointer",
        zIndex: 999,
      }}
    >
      {direction === "left" ? (
        <img src={leftArrow} alt="Previous" className={styles.leftArrow} />
      ) : (
        <img src={rightArrow} alt="Next" className={styles.rightArrow} />
      )}
    </div>
  );
};
