import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import styles from "./PastEvents.module.css";
import SimpleSlider from "../Components/Slider/Slider";
import client from "../config/contentful";

function PastEvents() {
  const [content, setContent] = useState([]);

  useEffect(() => {
    client
      .getEntries({
        content_type: "eventsPage",
      })
      .then((response) => {
        setContent(response.items[0].fields.events);
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <div className={styles.content}>
      {content.map((project, projectIndex) => (
        <div key={projectIndex}>
          <div className={styles.subtitle}>{project.fields.title}</div>
          <div className={styles.date}>{project.fields.date}</div>
          <div className={styles.tag}>
            <ReactMarkdown>{project.fields.subtitle}</ReactMarkdown>
          </div>
          <div className={styles.imageContainer}>
            <SimpleSlider
              images={project.fields.images.map(
                (asset) => `https:${asset.fields.file.url}`
              )}
            />
          </div>
        </div>
      ))}
    </div>
  );
}

export default PastEvents;
