import React, { useEffect, useState } from "react";
import styles from "./Workshops.module.css";
import client from "../config/contentful";
import SimpleSlider from "../Components/Slider/Slider";

function Workshops() {
  const [content, setContent] = useState([]);

  useEffect(() => {
    client
      .getEntries({
        content_type: "workshopPage",
      })
      .then((response) => {
        console.log("response, ", response);
        setContent(response.items[0].fields.workshops);
      })
      .catch((error) => console.error(error));
  }, []);

  const parseSubtitle = (text) => {
    const parts = text.split(/(\[.*?\]\(.*?\))/g); // Split text into parts
    return parts.map((part, index) => {
      const match = part.match(/\[(.*?)\]\((.*?)\)/); // Match [text](url) syntax
      if (match) {
        return (
          <a
            key={index}
            href={match[2]}
            target="_blank"
            rel="noopener noreferrer"
          >
            {match[1]}
          </a>
        );
      }
      return part; // Return plain text for non-link parts
    });
  };

  console.log(content);

  return (
    <div className={styles.content}>
      <div>
        <div className={styles.headerTag}>
          We offer a range of workshops for individuals and groups. Costs depend
          on numbers, venue and location. Bespoke workshops can be arranged if
          you have a special request. Some examples of workshops we offer are
          listed below.
        </div>
        <img
          src={"https://strawcraft.s3.eu-west-2.amazonaws.com/desk.webp"}
          alt={`Workshop desk`}
          className={styles.headerImage}
        />
      </div>
      {content.map((project, projectIndex) => (
        <div key={projectIndex}>
          <div className={styles.subtitle}>{project.fields.title}</div>
          <div className={styles.tag}>
            {parseSubtitle(project.fields.subtitle)}
          </div>
          <div className={styles.imageContainer}>
            <SimpleSlider
              images={project.fields.images.map(
                (asset) => `https:${asset.fields.file.url}`
              )}
            />
          </div>
        </div>
      ))}
    </div>
  );
}

export default Workshops;
