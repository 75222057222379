import React, { useEffect, useState } from "react";
import styles from "./Content.module.css";
import client from "../config/contentful";
import SimpleSlider from "../Components/Slider/Slider";

function Content() {
  const [content, setContent] = useState([]);

  useEffect(() => {
    client
      .getEntries({
        content_type: "projectPage",
      })
      .then((response) => {
        setContent(response.items[0].fields.projects);
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <div className={styles.content}>
      {content.map((project, projectIndex) => (
        <div key={projectIndex}>
          <div className={styles.subtitle}>{project.fields.title}</div>
          <div className={styles.tag}>{project.fields.subtitle}</div>
          <div className={styles.imageContainer}>
            <SimpleSlider
              images={project.fields.images.map(
                (asset) => `https:${asset.fields.file.url}`
              )}
            />
          </div>
        </div>
      ))}
    </div>
  );
}

export default Content;
